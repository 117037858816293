module.exports = [
  {
    "type": "A2",
    "id": 1,
    "floor": "2",
    "area": "92.81",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/A.jpg",
    "bathroomCount": 2,
    "entrance": "7.23",
    "guestBathroom": 2.59,
    "laundry": "0.47",
    "livingRoom": "20.84",
    "kitchen": "8.09",
    "corridor": "8.00",
    "dinnerRoom": [
      "7.19",
      "7.19"
    ],
    "lobby": "7.23",
    "bathrooms": [
      "3.94",
      "5.98"
    ],
    "bedrooms": [
      "14.57",
      "16.50"
    ]
  },
  {
    "type": "В2",
    "floor": "2",
    "bathroomCount": 2,
    "bedroomCount": 1,
    "area": "67.92",
    "entrance": "5.56",
    "plan": "/static/apartments/B.jpg",
    "kitchen": "11.21",
    "livingRoom": "18.69",
    "кладовая": "0.63",
    "lobby": "5.56",
    "bathrooms": [
      "3.57",
      "5.93"
    ],
    "wardrobe": "6.74",
    "bedrooms": "15.59",
    "pantry": "0.63"
  },
  {
    "type": "C2",
    "id": 7,
    "floor": "2",
    "area": "67.57",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/C.jpg",
    "bathroomCount": 2,
    "entrance": "5.23",
    "guestBathroom": 2.47,
    "livingRoom": "18.69",
    "kitchen": "10.65",
    "кладовая": "0.63",
    "lobby": "5.23",
    "bathrooms": [
      "4.11",
      "5.93"
    ],
    "wardrobe": "6.74",
    "bedrooms": "15.59",
    "pantry": "0.63"
  },
  {
    "type": "D2",
    "id": 10,
    "floor": "2",
    "area": "66.3",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/D.jpg",
    "bathroomCount": 2,
    "entrance": "2.93",
    "guestBathroom": 2.37,
    "livingRoom": "18.75",
    "kitchen": "12.93",
    "lobby": "2.93",
    "bathrooms": [
      "3.07",
      "7.97"
    ],
    "bedrooms": "14.29",
    "wardrobe": "6.36"
  },
  {
    "type": "E2",
    "id": 13,
    "floor": "2",
    "area": "170.44",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/E.jpg",
    "bathroomCount": 3,
    "entrance": "11.35",
    "corridor": "14.93",
    "guestBathroom": 2.71,
    "laundry": [
      "1.62",
      "1.62"
    ],
    "livingRoom": "31.77",
    "canteen": 22.73,
    "kitchen": "16.23",
    "utilityRoom": 0.57,
    "dinnerRoom": "22.39",
    "lobby": "11.35",
    "bathrooms": [
      "2.41",
      "9.12",
      "5.96"
    ],
    "bedrooms": [
      "14.44",
      "15.06",
      "16.61"
    ],
    "wardrobe": "8.55"
  },
  {
    "type": "F2",
    "id": 16,
    "floor": "2",
    "area": "61.41",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/F.jpg",
    "bathroomCount": 2,
    "entrance": "6.06",
    "guestBathroom": 2.95,
    "livingRoom": "20.22",
    "kitchen": "6.25",
    "lobby": "6.06",
    "bathrooms": [
      "2.31",
      "4.94"
    ],
    "wardrobe": "6.38",
    "bedrooms": "15.25"
  },
  {
    "type": "G2",
    "id": 23,
    "floor": "2",
    "area": "117.99",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/G.jpg",
    "bathroomCount": 3,
    "entrance": "8.70",
    "corridor": "4.36",
    "guestBathroom": 3.19,
    "laundry": "3.57",
    "livingRoom": "22.11",
    "canteen": 14.64,
    "kitchen": "8.91",
    "dinnerRoom": "15.84",
    "lobby": "8.70",
    "bathrooms": [
      "2.42",
      "5.80",
      "7.54"
    ],
    "bedrooms": [
      "16.18",
      "16.74"
    ],
    "wardrobe": "5.82"
  },
  {
    "type": "H2",
    "id": 30,
    "floor": "2",
    "area": "98.01",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/H.jpg",
    "bathroomCount": 3,
    "entrance": "7.89",
    "corridor": "10.35",
    "guestBathroom": 2.06,
    "laundry": "1.20",
    "livingRoom": "11.99",
    "canteen": 17.29,
    "dinnerRoom": "11.15",
    "kitchen": "7.27",
    "lobby": "7.89",
    "bathrooms": [
      "2.18",
      "5.29",
      "3.91"
    ],
    "bedrooms": [
      "13.69",
      "12.67"
    ],
    "wardrobe": [
      "5.76",
      "4.66"
    ]
  },
  {
    "type": "I2",
    "id": 33,
    "floor": "2",
    "area": "128.78",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/I.jpg",
    "bathroomCount": 3,
    "entrance": "9.94",
    "corridor": [
      "5.06",
      "5.06"
    ],
    "guestBathroom": 2.7,
    "laundry": "2.13",
    "livingRoom": "25.01",
    "kitchen": "9.90",
    "dinnerRoom": "16.01",
    "lobby": "9.94",
    "bathrooms": [
      "2.51",
      "6.05",
      "7.17"
    ],
    "bedrooms": [
      "16.82",
      "17.79"
    ],
    "wardrobe": "10.39"
  },
  {
    "type": "A3",
    "id": 2,
    "floor": "3",
    "area": "92.81",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/A.jpg",
    "bathroomCount": 2,
    "entrance": "7.23",
    "guestBathroom": 2.59,
    "laundry": "0.47",
    "livingRoom": "20.84",
    "kitchen": "8.09",
    "corridor": "8.00",
    "dinnerRoom": [
      "7.19",
      "7.19"
    ],
    "lobby": "7.23",
    "bathrooms": [
      "3.94",
      "5.98"
    ],
    "bedrooms": [
      "14.57",
      "16.50"
    ]
  },
  {
    "type": "В3",
    "floor": "3",
    "bathroomCount": 2,
    "bedroomCount": 1,
    "area": "67.92",
    "entrance": "5.56",
    "plan": "/static/apartments/B.jpg",
    "kitchen": "11.21",
    "livingRoom": "18.69",
    "кладовая": "0.63",
    "lobby": "5.56",
    "bathrooms": [
      "3.57",
      "5.93"
    ],
    "wardrobe": "6.74",
    "bedrooms": "15.59",
    "pantry": "0.63"
  },
  {
    "type": "C3",
    "id": 8,
    "floor": "3",
    "area": "67.57",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/C.jpg",
    "bathroomCount": 2,
    "entrance": "5.23",
    "guestBathroom": 2.47,
    "livingRoom": "18.69",
    "kitchen": "10.65",
    "кладовая": "0.63",
    "lobby": "5.23",
    "bathrooms": [
      "4.11",
      "5.93"
    ],
    "wardrobe": "6.74",
    "bedrooms": "15.59",
    "pantry": "0.63"
  },
  {
    "type": "D3",
    "id": 11,
    "floor": "3",
    "area": "66.3",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/D.jpg",
    "bathroomCount": 2,
    "entrance": "2.93",
    "guestBathroom": 2.37,
    "livingRoom": "18.75",
    "kitchen": "12.93",
    "lobby": "2.93",
    "bathrooms": [
      "3.07",
      "7.97"
    ],
    "bedrooms": "14.29",
    "wardrobe": "6.36"
  },
  {
    "type": "E3",
    "id": 14,
    "floor": "3",
    "area": "170.44",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/E.jpg",
    "bathroomCount": 3,
    "entrance": "11.35",
    "corridor": "14.93",
    "guestBathroom": 2.71,
    "laundry": [
      "1.62",
      "1.62"
    ],
    "livingRoom": "31.77",
    "canteen": 22.73,
    "kitchen": "16.23",
    "utilityRoom": 0.57,
    "dinnerRoom": "22.39",
    "lobby": "11.35",
    "bathrooms": [
      "2.41",
      "9.12",
      "5.96"
    ],
    "bedrooms": [
      "14.44",
      "15.06",
      "16.61"
    ],
    "wardrobe": "8.55"
  },
  {
    "type": "F3",
    "id": 17,
    "floor": "3",
    "area": "61.41",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/F.jpg",
    "bathroomCount": 2,
    "entrance": "6.06",
    "guestBathroom": 2.95,
    "livingRoom": "20.22",
    "kitchen": "6.25",
    "lobby": "6.06",
    "bathrooms": [
      "2.31",
      "4.94"
    ],
    "wardrobe": "6.38",
    "bedrooms": "15.25"
  },
  {
    "type": "G3",
    "id": 24,
    "floor": "3",
    "area": "117.99",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/G.jpg",
    "bathroomCount": 3,
    "entrance": "8.70",
    "corridor": "4.36",
    "guestBathroom": 3.19,
    "laundry": "3.57",
    "livingRoom": "22.11",
    "canteen": 14.64,
    "kitchen": "8.91",
    "dinnerRoom": "15.84",
    "lobby": "8.70",
    "bathrooms": [
      "2.42",
      "5.80",
      "7.54"
    ],
    "bedrooms": [
      "16.18",
      "16.74"
    ],
    "wardrobe": "5.82"
  },
  {
    "type": "H3",
    "id": 31,
    "floor": "3",
    "area": "98.01",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/H.jpg",
    "bathroomCount": 3,
    "entrance": "7.89",
    "corridor": "10.35",
    "guestBathroom": 2.06,
    "laundry": "1.20",
    "livingRoom": "11.99",
    "canteen": 17.29,
    "dinnerRoom": "11.15",
    "kitchen": "7.27",
    "lobby": "7.89",
    "bathrooms": [
      "2.18",
      "5.29",
      "3.91"
    ],
    "bedrooms": [
      "13.69",
      "12.67"
    ],
    "wardrobe": [
      "5.76",
      "4.66"
    ]
  },
  {
    "type": "I3",
    "id": 34,
    "floor": "3",
    "area": "128.78",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/I.jpg",
    "bathroomCount": 3,
    "entrance": "9.94",
    "corridor": [
      "5.06",
      "5.06"
    ],
    "guestBathroom": 2.7,
    "laundry": "2.13",
    "livingRoom": "25.01",
    "kitchen": "9.90",
    "dinnerRoom": "16.01",
    "lobby": "9.94",
    "bathrooms": [
      "2.51",
      "6.05",
      "7.17"
    ],
    "bedrooms": [
      "16.82",
      "17.79"
    ],
    "wardrobe": "10.39"
  },
  {
    "type": "A4",
    "id": 3,
    "floor": "4",
    "area": "92.81",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/A.jpg",
    "bathroomCount": 2,
    "entrance": "7.23",
    "guestBathroom": 2.59,
    "laundry": "0.47",
    "livingRoom": "20.84",
    "kitchen": "8.09",
    "corridor": "8.00",
    "dinnerRoom": [
      "7.19",
      "7.19"
    ],
    "lobby": "7.23",
    "bathrooms": [
      "3.94",
      "5.98"
    ],
    "bedrooms": [
      "14.57",
      "16.50"
    ]
  },
  {
    "type": "В4",
    "floor": "4",
    "bathroomCount": 2,
    "bedroomCount": 1,
    "area": "67.92",
    "entrance": "5.56",
    "plan": "/static/apartments/B.jpg",
    "kitchen": "11.21",
    "livingRoom": "18.69",
    "кладовая": "0.63",
    "lobby": "5.56",
    "bathrooms": [
      "3.57",
      "5.93"
    ],
    "wardrobe": "6.74",
    "bedrooms": "15.59",
    "pantry": "0.63"
  },
  {
    "type": "C4",
    "id": 9,
    "floor": "4",
    "area": "67.57",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/C.jpg",
    "bathroomCount": 2,
    "entrance": "5.23",
    "guestBathroom": 2.47,
    "livingRoom": "18.69",
    "kitchen": "10.65",
    "кладовая": "0.63",
    "lobby": "5.23",
    "bathrooms": [
      "4.11",
      "5.93"
    ],
    "wardrobe": "6.74",
    "bedrooms": "15.59",
    "pantry": "0.63"
  },
  {
    "type": "D4",
    "id": 12,
    "floor": "4",
    "area": "66.3",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/D.jpg",
    "bathroomCount": 2,
    "entrance": "2.93",
    "guestBathroom": 2.37,
    "livingRoom": "18.75",
    "kitchen": "12.93",
    "lobby": "2.93",
    "bathrooms": [
      "3.07",
      "7.97"
    ],
    "bedrooms": "14.29",
    "wardrobe": "6.36"
  },
  {
    "type": "E4",
    "id": 15,
    "floor": "4",
    "area": "170.44",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/C.jpg",
    "bathroomCount": 3,
    "entrance": "11.35",
    "corridor": "14.93",
    "guestBathroom": 2.71,
    "laundry": [
      "1.62",
      "1.62"
    ],
    "livingRoom": "31.77",
    "canteen": 22.73,
    "kitchen": "16.23",
    "utilityRoom": 0.57,
    "dinnerRoom": "22.39",
    "lobby": "11.35",
    "bathrooms": [
      "2.41",
      "9.12",
      "5.96"
    ],
    "bedrooms": [
      "14.44",
      "15.06",
      "16.61"
    ],
    "wardrobe": "8.55"
  },
  {
    "type": "F4",
    "id": 18,
    "floor": "4",
    "area": "61.41",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/F.jpg",
    "bathroomCount": 2,
    "entrance": "6.06",
    "guestBathroom": 2.95,
    "livingRoom": "20.22",
    "kitchen": "6.25",
    "lobby": "6.06",
    "bathrooms": [
      "2.31",
      "4.94"
    ],
    "wardrobe": "6.38",
    "bedrooms": "15.25"
  },
  {
    "type": "G4",
    "id": 25,
    "floor": "4",
    "area": "117.99",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/G.jpg",
    "bathroomCount": 3,
    "entrance": "8.70",
    "corridor": "4.36",
    "guestBathroom": 3.19,
    "laundry": "3.57",
    "livingRoom": "22.11",
    "canteen": 14.64,
    "kitchen": "8.91",
    "dinnerRoom": "15.84",
    "lobby": "8.70",
    "bathrooms": [
      "2.42",
      "5.80",
      "7.54"
    ],
    "bedrooms": [
      "16.18",
      "16.74"
    ],
    "wardrobe": "5.82"
  },
  {
    "type": "H4",
    "id": 32,
    "floor": "4",
    "area": "98.01",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/H.jpg",
    "bathroomCount": 3,
    "entrance": "7.89",
    "corridor": "10.35",
    "guestBathroom": 2.06,
    "laundry": "1.20",
    "livingRoom": "11.99",
    "canteen": 17.29,
    "dinnerRoom": "11.15",
    "kitchen": "7.27",
    "lobby": "7.89",
    "bathrooms": [
      "2.18",
      "5.29",
      "3.91"
    ],
    "bedrooms": [
      "13.69",
      "12.67"
    ],
    "wardrobe": [
      "5.76",
      "4.66"
    ]
  },
  {
    "type": "I4",
    "id": 35,
    "floor": "4",
    "area": "128.78",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/I.jpg",
    "bathroomCount": 3,
    "entrance": "9.94",
    "corridor": [
      "5.06",
      "5.06"
    ],
    "guestBathroom": 2.7,
    "laundry": "2.13",
    "livingRoom": "25.01",
    "kitchen": "9.90",
    "dinnerRoom": "16.01",
    "lobby": "9.94",
    "bathrooms": [
      "2.51",
      "6.05",
      "7.17"
    ],
    "bedrooms": [
      "16.82",
      "17.79"
    ],
    "wardrobe": "10.39"
  },
  {
    "type": "F5",
    "id": 19,
    "floor": "5",
    "area": "61.52",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/F.jpg",
    "bathroomCount": 2,
    "entrance": "6.12",
    "guestBathroom": 2.95,
    "livingRoom": "20.21",
    "kitchen": "6.25",
    "corridor": "6.41",
    "lobby": "6.12",
    "bathrooms": [
      "2.34",
      "4.94"
    ],
    "bedrooms": "15.25"
  },
  {
    "type": "G5",
    "id": 26,
    "floor": "5",
    "area": "118.24",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/G.jpg",
    "bathroomCount": 3,
    "entrance": "8.70",
    "corridor": "4.36",
    "guestBathroom": 3.19,
    "laundry": "3.57",
    "livingRoom": "22.19",
    "canteen": 14.64,
    "kitchen": "8.95",
    "dinnerRoom": "15.95",
    "lobby": "8.70",
    "bathrooms": [
      "2.43",
      "5.80",
      "7.38"
    ],
    "bedrooms": [
      "16.23",
      "16.82"
    ],
    "wardrobe": "5.86"
  },
  {
    "type": "J5",
    "id": 36,
    "floor": "5",
    "area": "172.35",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/J.jpg",
    "bathroomCount": 3,
    "entrance": "10.72",
    "corridor": "2.89",
    "guestBathroom": [
      "2.38",
      "2.38"
    ],
    "laundry": "2.58",
    "livingRoom": "33.89",
    "kitchen": "18.70",
    "dinnerRoom": "21.48",
    "lobby": "10.72",
    "bathrooms": [
      "3.63",
      "5.27",
      "8.10"
    ],
    "bedrooms": [
      "15.52",
      "19.17",
      "21.99"
    ],
    "wardrobe": "6.03"
  },
  {
    "type": "K5",
    "id": 40,
    "floor": "5",
    "area": "158.13",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/K.jpg",
    "bathroomCount": 3,
    "corridor": [
      "10.20",
      "7.89"
    ],
    "guestBathroom": 2.16,
    "laundry": [
      "1.17",
      "1.17"
    ],
    "livingRoom": "24.25",
    "kitchen": "18.40",
    "utilityRoom": 0.97,
    "entrance": "9.53",
    "dinnerRoom": "16.93",
    "lobby": "9.53",
    "bathrooms": [
      "2.64",
      "10.61",
      "7.37"
    ],
    "bedrooms": [
      "16.11",
      "15.17",
      "17.85"
    ]
  },
  {
    "type": "L5",
    "id": 44,
    "floor": "5",
    "area": "138.69",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/L.jpg",
    "bathroomCount": 3,
    "corridor": "5.12",
    "guestBathroom": 2.14,
    "laundry": "3.56",
    "livingRoom": "28.44",
    "kitchen": "9.43",
    "entrance": "7.77",
    "dinnerRoom": "18.95",
    "lobby": "7.77",
    "bathrooms": [
      "2.36",
      "6.11",
      "8.41"
    ],
    "bedrooms": [
      "15.34",
      "20.91"
    ],
    "wardrobe": "12.28"
  },
  {
    "type": "M5",
    "id": 48,
    "floor": "5",
    "area": "165",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": true,
    "plan": "/static/apartments/M.jpg",
    "bathroomCount": 3,
    "corridor": "14.31",
    "guestBathroom": 2.72,
    "laundry": [
      "1.95",
      "1.95"
    ],
    "livingRoom": [
      "32.03",
      "32.03"
    ],
    "canteen": 26.86,
    "kitchen": [
      "15.02",
      "15.02"
    ],
    "utilityRoom": 1.08,
    "entrance": "6.61",
    "dinnerRoom": [
      "21.91",
      "21.91"
    ],
    "Teppaca": [
      "88.74",
      "88.74"
    ],
    "lobby": "6.61",
    "bathrooms": [
      "2.68",
      "6.19",
      "8.67"
    ],
    "bedrooms": [
      "15.11",
      "16.12",
      "16.18"
    ],
    "wardrobe": "8.22"
  },
  {
    "type": "F6",
    "id": 20,
    "floor": "6",
    "area": "61.52",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/F.jpg",
    "bathroomCount": 2,
    "entrance": "6.12",
    "guestBathroom": 2.95,
    "livingRoom": "20.21",
    "kitchen": "6.25",
    "corridor": "6.41",
    "lobby": "6.12",
    "bathrooms": [
      "2.34",
      "4.94"
    ],
    "bedrooms": "15.25"
  },
  {
    "type": "G6",
    "id": 27,
    "floor": "6",
    "area": "118.24",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/G.jpg",
    "bathroomCount": 3,
    "entrance": "8.70",
    "corridor": "4.36",
    "guestBathroom": 3.19,
    "laundry": "3.57",
    "livingRoom": "22.19",
    "canteen": 14.64,
    "kitchen": "8.95",
    "dinnerRoom": "15.95",
    "lobby": "8.70",
    "bathrooms": [
      "2.43",
      "5.80",
      "7.38"
    ],
    "bedrooms": [
      "16.23",
      "16.82"
    ],
    "wardrobe": "5.86"
  },
  {
    "type": "J6",
    "id": 37,
    "floor": "6",
    "area": "172.35",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/J.jpg",
    "bathroomCount": 4,
    "entrance": "10.72",
    "corridor": "2.89",
    "guestBathroom": 2.08,
    "laundry": "2.58",
    "livingRoom": "33.89",
    "kitchen": "18.70",
    "dinnerRoom": "21.48",
    "lobby": "10.72",
    "bathrooms": [
      "3.63",
      "5.27",
      "8.10",
      "2.38"
    ],
    "bedrooms": [
      "15.52",
      "19.17",
      "21.99"
    ],
    "wardrobe": "6.03"
  },
  {
    "type": "K6",
    "id": 41,
    "floor": "6",
    "area": "158.13",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/K.jpg",
    "bathroomCount": 3,
    "corridor": [
      "10.20",
      "7.89"
    ],
    "guestBathroom": 2.16,
    "laundry": [
      "1.17",
      "1.17"
    ],
    "livingRoom": "24.25",
    "kitchen": "18.40",
    "utilityRoom": 0.97,
    "entrance": "9.53",
    "dinnerRoom": "16.93",
    "lobby": "9.53",
    "bathrooms": [
      "2.64",
      "10.61",
      "7.37"
    ],
    "bedrooms": [
      "16.11",
      "15.17",
      "17.85"
    ]
  },
  {
    "type": "L6",
    "id": 45,
    "floor": "6",
    "area": "138.69",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/L.jpg",
    "bathroomCount": 3,
    "corridor": "5.12",
    "guestBathroom": 2.14,
    "laundry": "3.56",
    "livingRoom": "28.44",
    "kitchen": "9.43",
    "entrance": "7.77",
    "dinnerRoom": "18.95",
    "lobby": "7.77",
    "bathrooms": [
      "2.36",
      "6.11",
      "8.41"
    ],
    "bedrooms": [
      "15.34",
      "20.91"
    ],
    "wardrobe": "12.28"
  },
  {
    "type": "N6",
    "id": 49,
    "floor": "6",
    "area": "176.95",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/N.jpg",
    "bathroomCount": 3,
    "corridor": "14.31",
    "entrance": "6.61",
    "guestBathroom": 1.89,
    "laundry": [
      "1.95",
      "1.95"
    ],
    "livingRoom": [
      "41.99",
      "41.99"
    ],
    "canteen": 23.02,
    "kitchen": [
      "15.02",
      "15.02"
    ],
    "dinnerRoom": [
      "23.90",
      "23.90"
    ],
    "lobby": "6.61",
    "bathrooms": [
      "2.68",
      "6.19",
      "8.67"
    ],
    "bedrooms": [
      "15.11",
      "16.12",
      "16.18"
    ],
    "wardrobe": "8.22"
  },
  {
    "type": "F7",
    "id": 21,
    "floor": "7",
    "area": "61.52",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/F.jpg",
    "bathroomCount": 2,
    "entrance": "6.12",
    "guestBathroom": 2.95,
    "livingRoom": "20.21",
    "kitchen": "6.25",
    "corridor": "6.41",
    "lobby": "6.12",
    "bathrooms": [
      "2.34",
      "4.94"
    ],
    "bedrooms": "15.25"
  },
  {
    "type": "G7",
    "id": 28,
    "floor": "7",
    "area": "118.24",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/G.jpg",
    "bathroomCount": 3,
    "entrance": "8.70",
    "corridor": "4.36",
    "guestBathroom": 3.19,
    "laundry": "3.57",
    "livingRoom": "22.19",
    "canteen": 14.64,
    "kitchen": "8.95",
    "dinnerRoom": "15.95",
    "lobby": "8.70",
    "bathrooms": [
      "2.43",
      "5.80",
      "7.38"
    ],
    "bedrooms": [
      "16.23",
      "16.82"
    ],
    "wardrobe": "5.86"
  },
  {
    "type": "J7",
    "id": 38,
    "floor": "7",
    "area": "172.35",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/J.jpg",
    "bathroomCount": 4,
    "entrance": "10.72",
    "corridor": "2.89",
    "guestBathroom": 2.08,
    "laundry": "2.58",
    "livingRoom": "33.89",
    "kitchen": "18.70",
    "dinnerRoom": "21.48",
    "lobby": "10.72",
    "bathrooms": [
      "3.63",
      "5.27",
      "8.10",
      "2.38"
    ],
    "bedrooms": [
      "15.52",
      "19.17",
      "21.99"
    ],
    "wardrobe": "6.03"
  },
  {
    "type": "K7",
    "id": 42,
    "floor": "7",
    "area": "158.13",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/K.jpg",
    "bathroomCount": 3,
    "corridor": [
      "10.20",
      "7.89"
    ],
    "guestBathroom": 2.16,
    "laundry": [
      "1.17",
      "1.17"
    ],
    "livingRoom": "24.25",
    "kitchen": "18.40",
    "utilityRoom": 0.97,
    "entrance": "9.53",
    "dinnerRoom": "16.93",
    "lobby": "9.53",
    "bathrooms": [
      "2.64",
      "10.61",
      "7.37"
    ],
    "bedrooms": [
      "16.11",
      "15.17",
      "17.85"
    ]
  },
  {
    "type": "L7",
    "id": 46,
    "floor": "7",
    "area": "138.69",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/L.jpg",
    "bathroomCount": 3,
    "corridor": "5.12",
    "guestBathroom": 2.14,
    "laundry": "3.56",
    "livingRoom": "28.44",
    "kitchen": "9.43",
    "entrance": "7.77",
    "dinnerRoom": "18.95",
    "lobby": "7.77",
    "bathrooms": [
      "2.36",
      "6.11",
      "8.41"
    ],
    "bedrooms": [
      "15.34",
      "20.91"
    ],
    "wardrobe": "12.28"
  },
  {
    "type": "N7",
    "id": 50,
    "floor": "7",
    "area": "176.95",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/N.jpg",
    "bathroomCount": 3,
    "corridor": "14.31",
    "entrance": "6.61",
    "guestBathroom": 1.89,
    "laundry": [
      "1.95",
      "1.95"
    ],
    "livingRoom": [
      "41.99",
      "41.99"
    ],
    "canteen": 23.02,
    "kitchen": [
      "15.02",
      "15.02"
    ],
    "dinnerRoom": [
      "23.90",
      "23.90"
    ],
    "lobby": "6.61",
    "bathrooms": [
      "2.68",
      "6.19",
      "8.67"
    ],
    "bedrooms": [
      "15.11",
      "16.12",
      "16.18"
    ],
    "wardrobe": "8.22"
  },
  {
    "type": "F8",
    "id": 22,
    "floor": "8",
    "area": "61.52",
    "bedroomCount": 1,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/F.jpg",
    "bathroomCount": 2,
    "entrance": "6.12",
    "guestBathroom": 2.95,
    "livingRoom": "20.21",
    "kitchen": "6.25",
    "corridor": "6.41",
    "lobby": "6.12",
    "bathrooms": [
      "2.34",
      "4.94"
    ],
    "bedrooms": "15.25"
  },
  {
    "type": "G8",
    "id": 29,
    "floor": "8",
    "area": "118.24",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/G.jpg",
    "bathroomCount": 3,
    "entrance": "8.70",
    "corridor": "4.36",
    "guestBathroom": 3.19,
    "laundry": "3.57",
    "livingRoom": "22.19",
    "canteen": 14.64,
    "kitchen": "8.95",
    "dinnerRoom": "15.95",
    "lobby": "8.70",
    "bathrooms": [
      "2.43",
      "5.80",
      "7.38"
    ],
    "bedrooms": [
      "16.23",
      "16.82"
    ],
    "wardrobe": "5.86"
  },
  {
    "type": "J8",
    "id": 39,
    "floor": "8",
    "area": "172.35",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/J.jpg",
    "bathroomCount": 3,
    "entrance": "10.72",
    "corridor": "2.89",
    "guestBathroom": [
      "2.38",
      "2.38"
    ],
    "laundry": "2.58",
    "livingRoom": "33.89",
    "kitchen": "18.70",
    "dinnerRoom": "21.48",
    "lobby": "10.72",
    "bathrooms": [
      "3.63",
      "5.27",
      "8.10"
    ],
    "bedrooms": [
      "15.52",
      "19.17",
      "21.99"
    ],
    "wardrobe": "6.03"
  },
  {
    "type": "K8",
    "id": 43,
    "floor": "8",
    "area": "158.13",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/K.jpg",
    "bathroomCount": 3,
    "corridor": [
      "10.20",
      "7.89"
    ],
    "guestBathroom": 2.16,
    "laundry": [
      "1.17",
      "1.17"
    ],
    "livingRoom": "24.25",
    "kitchen": "18.40",
    "utilityRoom": 0.97,
    "entrance": "9.53",
    "dinnerRoom": "16.93",
    "lobby": "9.53",
    "bathrooms": [
      "2.64",
      "10.61",
      "7.37"
    ],
    "bedrooms": [
      "16.11",
      "15.17",
      "17.85"
    ]
  },
  {
    "type": "L8",
    "id": 47,
    "floor": "8",
    "area": "138.69",
    "bedroomCount": 2,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/L.jpg",
    "bathroomCount": 3,
    "corridor": "5.12",
    "guestBathroom": 2.14,
    "laundry": "3.56",
    "livingRoom": "28.44",
    "kitchen": "9.43",
    "entrance": "7.77",
    "dinnerRoom": "18.95",
    "lobby": "7.77",
    "bathrooms": [
      "2.36",
      "6.11",
      "8.41"
    ],
    "bedrooms": [
      "15.34",
      "20.91"
    ],
    "wardrobe": "12.28"
  },
  {
    "type": "N8",
    "id": 51,
    "floor": "8",
    "area": "176.95",
    "bedroomCount": 3,
    "penthouse": false,
    "terrace": false,
    "plan": "/static/apartments/N.jpg",
    "bathroomCount": 3,
    "corridor": "14.31",
    "entrance": "6.61",
    "guestBathroom": 1.89,
    "laundry": [
      "1.95",
      "1.95"
    ],
    "livingRoom": [
      "41.99",
      "41.99"
    ],
    "canteen": 23.02,
    "kitchen": [
      "15.02",
      "15.02"
    ],
    "dinnerRoom": [
      "23.90",
      "23.90"
    ],
    "lobby": "6.61",
    "bathrooms": [
      "2.68",
      "6.19",
      "8.67"
    ],
    "bedrooms": [
      "15.11",
      "16.12",
      "16.18"
    ],
    "wardrobe": "8.22"
  },
  {
    "type": "P9",
    "id": 53,
    "floor": "9",
    "area": "150.44",
    "bedroomCount": 1,
    "penthouse": true,
    "terrace": true,
    "plan": "/static/apartments/P.jpg",
    "bathroomCount": 3,
    "corridor": "11.24",
    "entrance": "12.27",
    "loggia": 9.17,
    "guestBathroom": 2.73,
    "laundry": [
      "5.08",
      "5.08"
    ],
    "livingRoom": [
      "20.20",
      "27.19",
      "20.20",
      "27.19"
    ],
    "kitchen": [
      "15.21",
      "15.21"
    ],
    "utilityRoom": 26.84,
    "dinnerRoom": [
      "14.64",
      "14.64"
    ],
    "balcony": [
      "25.82",
      "25.82"
    ],
    "lobby": "12.27",
    "bathrooms": [
      "3.22",
      "5.21",
      "9.17"
    ],
    "bedrooms": "20.61",
    "wardrobe": "6.40"
  },
  {
    "type": "O9",
    "id": 52,
    "floor": "9",
    "area": "220.97",
    "bedroomCount": 3,
    "penthouse": true,
    "terrace": true,
    "plan": "/static/apartments/O.jpg",
    "bathroomCount": 3,
    "corridor": "17.16",
    "entrance": "9.46",
    "guestBathroom": 3.59,
    "laundry": "4.35",
    "livingRoom": "40.45",
    "canteen": 18.96,
    "kitchen": "19.54",
    "dinnerRoom": "16.39",
    "cabinet": "10.75",
    "balcony": [
      "67.65",
      "67.65"
    ],
    "lobby": "9.46",
    "bathrooms": [
      "3.57",
      "8.15",
      "11.08"
    ],
    "wardrobe": [
      "7.37",
      "8.10"
    ],
    "bedrooms": [
      "18.45",
      "17.95",
      "28.20"
    ]
  }
]
