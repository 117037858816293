import React from 'react';
import styled from 'styled-components';
import Layout from '@p14/layouts';
import * as Components from '@p14/components';
import * as Modules from './_';
import items from '../../../../apartments.js';
import config from '@p14/config';

export const Footer = styled.p`
  margin: 50px auto 30px;
  max-width: 784px;
  font-family: Geometria;
  font-size: 12px;
  color: #000;
  line-height: 14px;
  text-align: center;
  color: ${config.colors.text};
`;

export const Apartment = props => {
  const {item} = props.pageContext;

  const meta = {
    title: `Апартаменты на ${item.floor} этаже, спален ${item.bedroomCount}, площадью ${item.area} м² в доме «Пироговская, 14», район Хамовники.`,
    keywords: `апартаменты, этаж ${item.floor}, спален ${item.bedroomCount}, ${item.area} м²  Пироговская, 14,  купить, Хамовники, Москва`,
    description: `Купить апартаменты площадью ${item.area} м² в Хамовниках, посмотреть планировку в доме «Пироговская, 14». Дом расположен в самом центре Москвы в нескольких минутах езды от Кремля.`,
  };

  const similar = items
    .filter(i => i.id !== item.id && i.area === item.area)
    .slice(0, 4);

  return (
    <Layout {...meta}>
      <Components.Container>
        <Modules.Header title={item.type} />

        <Modules.Container>
          <Modules.Aside {...item} />
          <Modules.Scheme src={item.plan} />
        </Modules.Container>

        <Modules.List items={similar} />

        <Footer
          dangerouslySetInnerHTML={{
            __html:
              'Площадь апартамента по проектной документации может быть изменена в порядке, предусмотренном 214<br/>ФЗ «Об участии в долевом строительстве» от 30 декабря 2004 года №210-ФЗ.',
          }}
        />
      </Components.Container>
    </Layout>
  );
};

export default Apartment;
