import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import * as Components from '@p14/components';
import config from '@p14/config';

export const Root = styled.section`
  max-width: 630px;
  margin: 60px auto 100px;
`;

export const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const Item = styled.li`
  padding: 8px 0;
  border-top: 1px solid #b48b72;

  &:last-child {
    padding: 8px 0 7px;
    border-bottom: 1px solid #b48b72;
  }
`;

export const ItemLink = styled(Link)`
  display: flex;
  min-height: 54px;
  text-decoration: none;
  transition: background-color 0.3s ease 0s;
  will-change: backgeound-color;

  &:hover {
    background-color: #f2f4f7;
  }
`;

export const Value = styled.p`
  margin: 0;
  flex: 0 0 25%;
  line-height: 54px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  font-family: 'Geometria';
  font-size: 16px;
  color: #9b9b9b;
`;

export const Header = styled.header`
  display: flex;
  padding: 6px 0;
`;

export const Title = styled.h4`
  flex: 0 0 25%;
  color: #9b9b9b;
  text-align: center;
  font-weight: bold;
  font-family: 'Austin';
  margin: 0;
  letter-spacing: 0.1px;

  @media ${config.media.mobile} {
    font-size: 14px;
  }
`;

export const titles = ['Тип', 'Этаж', 'Спальни', 'Площадь'];

export const List = ({items}) => items.length === 0 ? null : (
  <Root>
    <Components.H1>Похожие Апартаменты</Components.H1>

    <Container>
      <Header>
        {titles.map((title, index) => <Title key={index} dangerouslySetInnerHTML={{__html: title}}/>)}
      </Header>


      {items.map((item, index) => (
        <Item key={index}>
          <ItemLink to={`/apartments/${item.type}`}>
            <Value>{item.type}</Value>
            <Value>{item.floor}</Value>
            <Value>{item.bedroomCount}</Value>
            <Value>{item.area}</Value>
          </ItemLink>
        </Item>
      ))}
    </Container>
  </Root>
);

export default List;
