import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import {compose, withHandlers, setPropTypes, onlyUpdateForPropTypes} from 'recompose';
import config from '@p14/config';

export const Root = styled.label`
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  user-select: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 40px;
  }
`;

export const Label = styled.span`
  font-size: 16px;
  font-family: ${config.fonts.serif};
  font-weight: bold;
  display: block;
  text-align: center;
  position: relative;
  color: ${config.colors.main};

  a {
    color: #4a4a4a;
    text-decoration: none;
    display: inline-block;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 0.3s ease 0s;
    will-change: border-bottom-color;

    &:hover {
      border-bottom-color: #4a4a4a;
    }
  }

  &::before {
    content: '';
    margin-top: 1px;
    display: inline-block;
    vertical-align: top;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    border: 1px solid ${props => props.isChecked ? `${config.colors.main} !important` : config.colors.text};
    margin-right: 6px;
    transition: border-color 0.3s ease 0s;
    will-change: border-color;
  }

  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 4px;
    height: 7px;
    width: 7px;
    border-radius: 100%;
    background-color: ${config.colors.main};
    transition: opacity 0.3s ease 0s;
    will-change: opacity;
    opacity: ${props => props.isChecked ? 1 : 0};
  }

  &:hover::before {
    border-color: ${config.colors.main};
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
`;

export const Radio = ({label, isChecked, onChange, style, ...other}) => (
  <Root style={style}>
    <Label isChecked={isChecked} dangerouslySetInnerHTML={{__html: label}}/>
    <Input type="checkbox" onChange={onChange} checked={isChecked} {...other}/>
  </Root>
);

export const enhance = compose(
  withHandlers({
    onChange: props => () => props.onChange(props.value),
  }),
  onlyUpdateForPropTypes,
  setPropTypes({
    label: T.string.isRequired,
    isChecked: T.bool.isRequired,
    onChange: T.func.isRequired,
  }),
);

export default enhance(Radio);
