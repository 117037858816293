import React from 'react';
import styled from 'styled-components';
import config from '@p14/config';

export const Container = styled.div`
  display: flex;

  @media ${config.media.mobile} {
    display: block;
  }
`;

export default Container;
