import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import {compose, setPropTypes, onlyUpdateForPropTypes} from 'recompose';
import config from '@p14/config';

export const Root = styled.blockquote`
  margin: 0;
  text-align: center;
  padding: 52px 0 44px;
  background-color: rgba(245, 243, 241, 0.54);

  @media ${config.media.mobile} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Quote = styled.p`
  width: 624px;
  margin: 0 auto 28px;
  padding-bottom: 30px;
  line-height: 23px;
  font-size: 18px;
  color: #4a4a4a;
  font-family: 'Geometria';
  border-bottom: 1px solid #b48b72;

  @media ${config.media.mobile} {
    width: 100%;
    line-height: 18px;
    font-size: 14px;
  }
}`;

export const Caption = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: ${config.colors.main};
`;

export const Blockquote = ({quote, captions}) => (
  <Root>
    <Quote dangerouslySetInnerHTML={{__html: quote}}/>
    {captions.map((caption, index) => <Caption key={index} dangerouslySetInnerHTML={{__html: caption}}/>)}
  </Root>
);

export const enhance = compose(
  onlyUpdateForPropTypes,
  setPropTypes({
    quote: T.string.isRequired,
    captions: T.arrayOf(T.string.isRequired).isRequired,
  }),
);

export default enhance(Blockquote);
