import React from 'react';
import Link from 'gatsby-link';
import styled from 'styled-components';
import config from '@p14/config';

export const Root = styled.header`
  position: relative;
  height: 20px;
  width: 100%;
  margin: 40px 0;
`;

export const Back = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
`;

export const Title = styled.h1`
  margin: 0;
  font-family: ${config.fonts.serif};
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  text-transform: uppercase;
  color: ${config.colors.main};
  letter-spacing: 1px;
`;

export const Header = ({title}) => (
  <Root>
    <Back to="/apartments">
      <svg width="19" height="19">
        <path d="M2 17.556L17.556 2M2 2l15.556 15.556" stroke="#b48b72" strokeWidth="2" fill="none" strokeLinecap="square"/>
      </svg>
    </Back>

    <Title>{title}</Title>
  </Root>
);

export default Header;
