import React from 'react';
import T from 'prop-types';
import {connect} from 'react-redux';
import {compose, withState, setPropTypes, withHandlers} from 'recompose';
import styled from 'styled-components';
import Link from 'gatsby-link';
import Container from '../Container';
import Trigger from './Trigger';
import config from '@p14/config';

export const Root = styled.header`
  width: 100%;
  font-family: ${config.fonts.serif};
  box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.11);
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 108px;
  padding: 30px 0;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #b48b72;

  @media ${config.media.mobile} {
    height: 71px;
    padding: 24px 0;
  }
`;

export const Locale = styled.button`
  border: 0;
  padding: 0;
  float: left;
  outline: none;
  font-size: 16px;
  text-transform: uppercase;
  background-color: transparent;
  color: ${config.colors.text};
  transition: color 0.3s ease 0s;
  will-change: color;

  &:hover {
    color: ${config.colors.main};
  }

  @media ${config.media.mobile} {
    float: right;
  }
`;

export const Phone = styled.a`
  margin: 0;
  float: right;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
  color: #9a9a9a;
  transition: color 0.3s ease 0s;
  will-change: color;
  font-family: ${config.fonts.serif};

  &:hover {
    color: ${config.colors.main};
  }

  @media ${config.media.mobile} {
    float: none;
    display: none;
    text-align: center;
    margin: 8px 0 26px;
  }
`;

export const LogoIcon = props => (
  <Link to="/" {...props}>
    <svg viewBox="0 0 319.2 278.9">
      <path d="M214.7.2V3c0 .9.1 2 .1 3.4V10c0 3.1-.1 6.9-.1 11.3s-.3 9.5-.8 15.1a309.54 309.54 0 0 1-5.6 38 317.79 317.79 0 0 1-12.5 42.9 322.48 322.48 0 0 1-18.7 41.2 330 330 0 0 1-21.2 33.9c-3.4 4.8-6.7 9.1-9.7 12.9s-5.7 7-7.9 9.6-4 4.7-5.3 6.1-1.9 2.1-1.9 2.1h62.7v-7h-52.5c.4-.3.7-.6 1.1-.9l2.3-2q2.4-2.1 5.1-4.5c3.6-3.3 7.6-7 11.9-11.3a329.08 329.08 0 0 0 27.5-30.9 343.94 343.94 0 0 0 26.8-39.7 328.85 328.85 0 0 0 21.1-43.6 318.84 318.84 0 0 0 13.3-40.7c1.6-6.1 2.8-11.8 3.8-17 .5-2.6.9-5 1.2-7.3s.6-4.4.7-6.3c.1-1 .1-1.9.2-2.8a25.29 25.29 0 0 0 .1-2.8c0-1.4.1-2.5.1-3.5V0h-41.8zm67.9 271.7c-21 0-25.9-3.3-25.9-17.4V104.9c-1.5 2.4-3 4.8-4.5 7.1-4.8 7.4-10 14.6-15.5 21.6-7 8.9-14.5 17.4-22.1 25.3v95.5c0 14.1-4.9 17.4-25.9 17.4H173v7h125.4v-7h-15.8zm-173.1 0c-21 0-25.9-3.3-25.9-17.4V.2H0v7h15.9c21 0 25.9 3.3 25.9 17.4v229.9c0 14.1-4.9 17.4-25.9 17.4H0v7h125.4v-7h-15.9zm206.7-79.5l-1.7 9.1c-2.6 13.1-13.5 14.7-21.3 14.7h-15.8v7h41.8v-30.8h-3z"/>
    </svg>
  </Link>
);

export const Logo = styled(LogoIcon)`
  top: 50%;
  left: 50%;
  width: 53px;
  height: 46px;
  position: absolute;
  transform: translate(-50%, -50%);

  & svg {
    width: 53px;
    height: 46px;
    fill: #b48b72;
    transition: fill 0.3s ease 0s;
    will-change: fill;
  }

  &:hover svg {
    fill: #886149;
  }

  @media ${config.media.mobile} {
    width: 35px;
    height: 30px;

    & svg {
      width: 35px;
      height: 30px;
    }
  }
`;

export const links = [{
  href: '/apartments',
  text: 'Апартаменты',
}, {
  href: '/architecture',
  text: 'Архитектура',
}, {
  href: '/design',
  text: 'Дизайн',
}, {
  href: '/lifestyle',
  text: 'Стиль жизни',
}, {
  href: '/hamovniki',
  text: 'Хамовники',
}, {
  href: '/team',
  text: 'Команда',
}];

export const Nav = styled.nav`
  @media ${config.media.mobile} {
    position: relative;
    width: calc(100% + 40px);
    margin-left: -20px;
    z-index: 10000;
  }
`;

export const List = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  height: 100%;

  @media ${config.media.desktopOnly} {
    & > p,
    & > a {
      display: none !important;
    }
  }

  @media ${config.media.tabletOnly} {
    & > p,
    & > a {
      display: none !important;
    }
  }

  @media ${config.media.mobile} {
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: ${config.colors.light};
    box-shadow: 0 8px 6px 0px rgba(0,0,0,0.11);
    height: ${props => props.isOpen ? '347px' : '0px'};
    overflow: hidden;
    transition: height 0.15s ease 0s;
    will-change: height;
  }
`;

export const NavItem = styled.div`
  height: 100%;
  margin: 0 38px;
  text-align: center;

  @media ${config.media.tablet} {
    margin: 0 17px;
  }

  @media ${config.media.mobile} {
    height: auto;
    margin: 0;

    &:first-child {
      margin-top: 40px;
    }

    &:last-child {
      margin-bottom: 40px;
    }

    &:not(:last-child) {
      margin-bottom: 31px;
    }
  }
`;

export const NavLink = styled(Link)`
  color: #4a4a4a;
  display: block;
  font-size: 12px;
  line-height: 42px;
  font-weight: bold;
  font-family: ${config.fonts.serif};
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color 0.3s ease 0s;
  will-change: color;

  &:hover {
    color: ${config.colors.main};
  }

  @media ${config.media.tabletOnly} {
    line-height: 36px;
  }

  @media ${config.media.mobile} {
    line-height: 1;
  }
`;

export const Official = styled.p`
  float: left;
  color: #9b9b9b;
  opacity: 1;
  font-size: 14px;
  font-family: ${config.fonts.sansSerif};
  line-height: 18px;
  margin: 0;

  @media ${config.media.mobile} {
    display: none;
    float: none;
    text-align: center;
    padding: 0 4px;
    margin: 12px 0;
  }
`;

export const Header = ({isOpen, locale, onLocaleChange, onToggle}) => (
  <Root>
    <Container>
      <Wrapper>
        <Trigger isOpen={isOpen} onClick={onToggle}/>
        <Official>Официальный сайт застройщика</Official>
        {/* <Locale onClick={onLocaleChange}>{locale}</Locale> */}
        <Logo/>
        <Phone href="tel:74956206299">+7 495 620 62 99</Phone>
      </Wrapper>

      <Nav>
        <List isOpen={isOpen}>
          <Official style={{display: 'block'}}>Официальный сайт застройщика</Official>
          <Phone style={{display: 'block'}} href="tel:74956206299">+7 495 620 62 99</Phone>

          {links.map((link, index) => (
            <NavItem key={index}>
              <NavLink onClick={onToggle} activeStyle={{color: config.colors.main}} to={link.href}>
                {link.text}
              </NavLink>
            </NavItem>
          ))}
        </List>
      </Nav>
    </Container>
  </Root>
);

export const mapStateToProps = state => ({
  locale: state.locale,
});

export const mapDispatchToProps = {
  onLocaleChange: locale => ({type: 'LOCALE/CHANGE', locale}),
};

export const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('isOpen', 'toggleOpen', false),
  withHandlers({
    onLocaleChange: props => () => props.onLocaleChange(props.locale === 'en' ? 'ru' : 'en'),
    onToggle: props => () => props.toggleOpen(!props.isOpen),
  }),
  setPropTypes({
    isOpen: T.bool.isRequired,
    locale: T.oneOf(['en', 'ru']).isRequired,
    onLocaleChange: T.func.isRequired,
  }),
);

export default enhance(Header);
