export const config = {
  defaultLang: 'ru',
  fonts: {
    serif: 'Austin',
    sansSerif: 'Geometria',
  },
  colors: {
    main: '#b48b72',
    text: '#9a9a9a',
    dark: '#000',
    light: '#fff',
  },
  media: {
    desktopOnly: '(min-width: 1011px)',
    tablet: '(max-width: 1010px)',
    tabletOnly: '(max-width: 1010px) and (min-width: 740px)',
    mobile: '(max-width: 741px)'
  },
  prefix: 'http://localhost:1337',
};

export default config;
