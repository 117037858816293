import React from 'react';
import styled from 'styled-components';
import config from '@p14/config';

export const Root = styled.div`
  display: none;

  @media ${config.media.mobile} {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 20px;
  }
`;

export const Inner = styled.div`
  top: 50%;
  display: block;
  margin-top: -2px;

  &,
  &::after,
  &::before {
    position: absolute;
    width: 24px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform;
    border-radius: 4px;
    background-color: ${config.colors.text};
  }

  &::before,
  &::after {
    display: block;
    content: '';
  }

  &::before {
    top: -8px;
    transform: ${props => props.isOpen ? 'translate3d(-5px, 2px, 0) rotate(-45deg) scaleX(0.7)' : 'none'};
  }

  &::after {
    bottom: -8px;
    transform: ${props => props.isOpen ? 'translate3d(-5px, -2px, 0) rotate(45deg) scaleX(0.7)' : 'none'};
  }
`;

export const Trigger = props => (
  <Root {...props}>
    <Inner isOpen={props.isOpen}/>
  </Root>
);

export default Trigger;
