import React from 'react';
import styled from 'styled-components';
import config from '@p14/config';

export const H1 = styled.h1`
  margin: 42px 0;
  text-align: center;
  font-family: ${config.fonts.serif};
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 1px;
  line-height: 27px;
  color: ${config.colors.text};
  text-transform: uppercase;
`;

export default H1;
