import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import * as C from '../components';
import 'normalize.css';
import './index.css';
import config from '@p14/config';

export const Container = styled.div`
  padding-bottom: 319px;
  width: 100%;

  @media ${config.media.tabletOnly} {
    padding-bottom: 330px;
  }

  @media ${config.media.mobile} {
    padding-bottom: 0;
  }
`;

export const IndexLayout = ({children, title, description, keywords, ...other}) => (
  <div onContextMenu={e => e.target.nodeName === 'IMG' ? e.preventDefault() : null} style={{height: '100%'}}>
    <Helmet
      title={title || '«Пироговская, 14», клубный дом в Хамовниках от Элберт Инвестмент.'}
      meta={[
        {name: 'description', content: description || 'Официальный сайт дома «Пироговская, 14». Новый адрес в Хамовниках, в нескольких минутах от исторического центра. Ваша личная Москва: с небольшим частным садом, каминами и библиотекой от Andrew Macken и Hesse Macken Studio.'},
        {name: 'keywords', content: keywords || 'Пироговская, 14,  купить, апартаменты, квартиры, жилой, комплекс, элитная, недвижимость, Хамовники, жизнь, Москва, выбрать'},
      ]}
      link={[
        {rel: 'shortcut icon', type: 'image/png', href: '/favicon.png'}
      ]}
      />
    <div style={{minHeight: '100%'}}>
      <Container>
        <C.Header {...other}/>
        {children}
      </Container>
    </div>
    <C.Footer/>
  </div>
);

export default IndexLayout;
