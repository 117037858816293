import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import {compose, withState, withHandlers, setPropTypes, onlyUpdateForPropTypes} from 'recompose';

export const Root = styled.figure`
  flex: 1 1 466px;
  margin: 0;
`;

export const Image = styled.img`
  max-width: 100%;
  cursor: pointer;
`;

export const Modal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000000000;

  & div:first-child {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 3;
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
      cursor: pointer;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMTIiIGhlaWdodD0iMTEyIj48cGF0aCBkPSJNMTYgMTZsODAgODB6bTAgODBsODAtODB6IiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMTEuMyIvPjwvc3ZnPg==);
    }
  }
`;

export const Scheme = ({src, isOpen, onOpen, onClose}) => (
  <Root>
    <Image onClick={onOpen} src={src}/>

    {isOpen ? (
      <Modal>
        <div onClick={onClose} style={{backgroundImage: `url(${src})`}}/>
      </Modal>
    ) : null}
  </Root>
);

export const enhance = compose(
  withState('isOpen', 'toggleIsOpen', false),
  withHandlers({
    onOpen: props => () => {
      document.body.style.overflow = 'hidden';
      props.toggleIsOpen(true);
    },
    onClose: props => () => {
      document.body.style.overflow = '';
      props.toggleIsOpen(false)
    },
  }),
  onlyUpdateForPropTypes,
  setPropTypes({
    isOpen: T.bool.isRequired,
    src: T.string.isRequired,
    onOpen: T.func.isRequired,
    onClose: T.func.isRequired,
  }),
);

export default enhance(Scheme);
