import React from 'react';
import styled from 'styled-components';
import config from '@p14/config';

export const Container = styled.div`
  max-width: 948px;
  margin-left: auto;
  margin-right: auto;

  @media ${config.media.tabletOnly} {
    max-width: 690px;
  }

  @media ${config.media.mobile} {
    max-width: 100%;
    padding: 0 20px;
  }
`;

export default Container;
