import React from 'react';
import styled from 'styled-components';
import {flatten} from 'ramda';
import config from '@p14/config';

export const Root = styled.aside`
  flex: 0 0 308px;
  margin-right: 175px;

  @media ${config.media.tabletOnly} {
    margin-right: 50px;
  }

  @media ${config.media.mobile} {
    width: 100%;
    margin: 0 0 40px;
  }
`;

export const Table = styled.section`
  border-top: 1px solid ${config.colors.text};
`;

export const Row = styled.article`
  height: 30px;
  display: flex;
  white-space: nowrap;
  color: ${config.colors.text};
  justify-content: space-between;
  border-bottom: 1px solid ${config.colors.text};

  &:nth-child(-n+3) {
    color: ${config.colors.main};
  }
`;

export const Text = styled.p`
  margin: 0;
  line-height: 31px;
  font-family: ${config.fonts.sansSerif};
  font-weight: normal;
  font-size: 12px;
  min-width: 66px;
`;

export const Scheme = styled.figure`
  max-width: 149px;
  margin: 47px 0 0;

  img {
    max-width: 100%;
  }

  @media ${config.media.mobile} {
    margin-top: 40px;
  }
`;

export const Aside = props => (
  <Root>
    <Table>
      <Row>
        <Text>Общая площадь</Text>
        <Text>{props.area + ' м²'}</Text>
      </Row>
      <Row>
        <Text>Количество спален</Text>
        <Text>{props.bedroomCount}</Text>
      </Row>
      <Row>
        <Text>Количество санузлов</Text>
        <Text>{props.bathroomCount}</Text>
      </Row>
      {props.entrance ? (
        <Row>
          <Text>Прихожая</Text>
          <Text>{props.entrance}</Text>
        </Row>
      ) : null}
      {props.guestBathroom ? (
        <Row>
          <Text>Гостевой санузел</Text>
          <Text>{props.guestBathroom}</Text>
        </Row>
      ) : null}
      {props.wardrobe ? (
        <Row>
          <Text>Гардеробная</Text>
          <Text>{props.wardrobe}</Text>
        </Row>
      ) : null}
      {props.laundry ? (
        <Row>
          <Text>Постирочная</Text>
          <Text>{props.laundry}</Text>
        </Row>
      ) : null}
      {flatten([props.bedrooms]).length === 1 ? (
        <Row>
          <Text>Спальня</Text>
          <Text>{props.bedrooms[0]}</Text>
        </Row>
      ) : flatten([props.bedrooms]).map((bedroom, index) => (
        <Row key={index}>
          <Text>{`Спальня ${index + 1}`}</Text>
          <Text>{bedroom}</Text>
        </Row>
      ))}
      {flatten([props.bathrooms]).length === 1 ? (
        <Row>
          <Text>Санузел</Text>
          <Text>{props.bathrooms[0]}</Text>
        </Row>
      ) : flatten([props.bathrooms]).map((bedroom, index) => (
        <Row key={index}>
          <Text>{`Санузел ${index + 1}`}</Text>
          <Text>{bedroom}</Text>
        </Row>
      ))}
      {props.livingRoom ? (
        <Row>
          <Text>Гостиная</Text>
          <Text>{props.livingRoom}</Text>
        </Row>
      ) : null}
      {props.kitchen ? (
        <Row>
          <Text>Кухня</Text>
          <Text>{props.kitchen}</Text>
        </Row>
      ) : null}
    </Table>

    {/* <Scheme>
      <img src="/static/small-scheme.png"/>
    </Scheme> */}
  </Root>
);

export default Aside;
